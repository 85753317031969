import { graphql, Link as GatsbyLink } from 'gatsby';
import Plyr from 'plyr';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Flex, Image, Link, Text } from 'rebass';
import styled from 'styled-components';
import iconArrowArtist from '../assets/images/icon_arrow_artist.svg';
import plyrSprite from '../assets/images/plyr.svg';
import AnimatedContainer from '../components/AnimatedContainer';
import Banner from '../components/Banner';
import ContactItem from '../components/ContactItem';
import Footer from '../components/Footer';
import Header from '../components/Header';
import TextSection from '../components/TextSection';
import '../styles/plyr.css';

const InternalLink = styled(GatsbyLink)`
  color: black;
  display: inline-flex;
  text-decoration: underline;
  text-decoration-color: black;
  transition: all 0.1s ease-in-out;

  &:hover {
    text-decoration-color: transparent;
  }
`;

const ArtistLink = ({ artist }) => {
  if (!artist.url) {
    return (
      <Text
        color="black"
        fontFamily="grot10"
        fontSize={['16px', '16px', '21px']}
        letterSpacing="-0.5px"
        lineHeight={['22px', '22px', '25px']}
      >
        {artist.name}
        {artist.showAsterisk && '*'}
      </Text>
    );
  }

  if (artist.url[0] === '/') {
    return (
      <div>
        <InternalLink to={artist.url}>
          <Text
            color="black"
            fontFamily="grot10"
            fontSize={['16px', '16px', '21px']}
            letterSpacing="-0.5px"
            lineHeight={['22px', '22px', '25px']}
          >
            {artist.name}
          </Text>
        </InternalLink>
        {artist.showAsterisk && (
          <Text
            color="black"
            display="inline"
            fontFamily="grot10"
            fontSize={['16px', '16px', '21px']}
            letterSpacing="-0.5px"
            lineHeight={['22px', '22px', '25px']}
          >
            *
          </Text>
        )}
      </div>
    );
  }

  return (
    <div>
      <Link
        color="black"
        css={{
          '&:hover': {
            textDecorationColor: 'transparent',
          },
          display: 'inline-flex',
          textDecoration: 'underline',
          textDecorationColor: 'black',
          transition: 'all 0.1s ease-in-out',
        }}
        href={artist.url}
        rel="noopener noreferrer"
        target="_blank"
      >
        <Text
          color="black"
          fontFamily="grot10"
          fontSize={['16px', '16px', '21px']}
          letterSpacing="-0.5px"
          lineHeight={['22px', '22px', '25px']}
        >
          {artist.name}
        </Text>
      </Link>
      {artist.showAsterisk && (
        <Text
          color="black"
          display="inline"
          fontFamily="grot10"
          fontSize={['16px', '16px', '21px']}
          letterSpacing="-0.5px"
          lineHeight={['22px', '22px', '25px']}
        >
          *
        </Text>
      )}
      <Image
        display="inline"
        height={[8, 8, 10]}
        width={[8, 8, 10]}
        ml="8px"
        src={iconArrowArtist}
      />
    </div>
  );
};

class Publishing extends PureComponent {
  componentDidMount() {
    this.player = new Plyr('#player', {
      controls: ['play-large', 'play', 'progress', 'volume', 'fullscreen'],
      iconUrl: plyrSprite,
    });
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.destroy();
    }
  }

  render() {
    const {
      data: { pageData },
    } = this.props;

    return (
      <div>
        <Helmet>
          <title>Publishing</title>
        </Helmet>
        <Banner />
        <Header
          activePageName="PUBLISHING/A&R"
          rightText={
            pageData.videoVimeoId
              ? `SHOWREEL ${new Date().getFullYear()}`
              : 'OUR COLLABORATIONS'
          }
        />
        {pageData.videoVimeoId && (
          <Box
            css={{
              maxWidth: '1500px',
            }}
            id="content"
            mx="auto"
            mt={['25px', '30px', '70px']}
            px={[20, 30, 70]}
          >
            <AnimatedContainer>
              <div
                data-plyr-embed-id={pageData.videoVimeoId}
                data-plyr-provider="vimeo"
                id="player"
              />
            </AnimatedContainer>
          </Box>
        )}
        <Box
          css={{
            maxWidth: '1500px',
          }}
          id={pageData.videoVimeoId ? undefined : 'content'}
          mx="auto"
          mt={
            pageData.videoVimeoId ? ['25px', '70px'] : ['25px', '30px', '70px']
          }
          px={[20, 30, 70]}
        >
          <TextSection title="Artists">
            {pageData.artists.map((artist) => (
              <ArtistLink artist={artist} key={artist.name} />
            ))}
            <Text
              color="black"
              fontFamily="grot10"
              fontSize={['16px', '16px', '21px']}
              letterSpacing="-0.5px"
              lineHeight={['22px', '22px', '25px']}
              mt="16px"
            >
              * New Business Provider
            </Text>
          </TextSection>
          <TextSection title="Artist Collaborations">
            {pageData.artistCollaborations.map((artist) => (
              <Text
                color="black"
                fontFamily="grot10"
                fontSize={['16px', '16px', '21px']}
                key={artist}
                letterSpacing="-0.5px"
                lineHeight={['22px', '22px', '25px']}
              >
                {artist}
              </Text>
            ))}
          </TextSection>
          <TextSection title="Clients">
            {pageData.clients.map((client) => (
              <Flex alignItems="center" key={client.name}>
                <Text
                  color="black"
                  fontFamily="grot10"
                  fontSize={['16px', '16px', '21px']}
                  letterSpacing="-0.5px"
                  lineHeight={['22px', '22px', '25px']}
                >
                  {client.name}
                </Text>
                {client.tag && (
                  <Box
                    css={{
                      border: '1px solid black',
                      borderRadius: '2px',
                      textTransform: 'uppercase',
                    }}
                    letterSpacing="0.5px"
                    ml="8px"
                    mt="2px"
                    padding="2px 4px"
                    fontFamily="sans"
                    fontSize="8px"
                  >
                    {client.tag}
                  </Box>
                )}
              </Flex>
            ))}
          </TextSection>
          <TextSection flexContainer isLast title="Contact">
            {pageData.contacts.map((contact, index) => (
              <ContactItem
                contactsLength={pageData.contacts.length}
                email={contact.email}
                index={index}
                key={contact.email}
                name={contact.name}
                title={contact.title}
              />
            ))}
          </TextSection>
        </Box>
        <Footer activePageName="PUBLISHING/A&R" />
      </div>
    );
  }
}

Publishing.propTypes = {
  data: PropTypes.shape({
    pageData: PropTypes.shape({
      artistCollaborations: PropTypes.arrayOf(PropTypes.string.isRequired)
        .isRequired,
      artists: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          url: PropTypes.string,
        }).isRequired,
      ).isRequired,
      clients: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          tag: PropTypes.string,
        }).isRequired,
      ).isRequired,
      contacts: PropTypes.arrayOf(
        PropTypes.shape({
          email: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
        }).isRequired,
      ).isRequired,
      videoVimeoId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default Publishing;

export const query = graphql`
  {
    pageData: publishingPageJson {
      videoVimeoId
      artists {
        name
        url
        showAsterisk
      }
      artistsAsteriskLabel
      artistCollaborations
      clients {
        name
        tag
      }
      contacts {
        name
        title
        email
      }
    }
  }
`;
